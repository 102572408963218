<template>
  <div>
    <h3>Generate Monthly Report</h3>

    <!-- Trigger Button to Open Modal -->
    <v-btn color="primary" @click="showModal = true">Select Date Range</v-btn>

    <!-- Modal with Vuetify v-date-picker components -->
    <v-dialog v-model="showModal" persistent max-width="400px">
      <v-card>
        <v-card-title>Select Report Date Range</v-card-title>
        <v-card-text>
          <v-select
            :items="typeItems"
            item-text="text"
            item-value="value"
            outlined
            v-model="type"
            @change="onTypeChange"
          />

          <!-- Monthly Picker -->
          <v-menu
            v-if="type === 'Monthly'"
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="month"
                label="Select Month"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="month"
              type="month"
              @change="updateMonthlyDates"
            ></v-date-picker>
          </v-menu>
          <v-menu
            ref="startMenu"
            v-if="type !== 'Monthly'"
            v-model="startMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="start"
                label="Start Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="start"
              @input="startMenu = false"
            ></v-date-picker>
          </v-menu>

          <!-- End Date Picker -->
          <v-menu
            ref="endMenu"
            v-if="type !== 'Monthly'"
            v-model="endMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="end"
                label="End Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="end"
              @input="endMenu = false"
            ></v-date-picker>
          </v-menu>

          <!-- Source Selection -->
          <v-select
            v-model="source"
            :items="['Medshield', 'Bonitas']"
            label="Source"
            placeholder="Select Source"
            clearable
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeMainDialogOpenOther"
            >Next</v-btn
          >
          <v-btn color="red darken-1" text @click="showModal = false"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span>Lead Descriptions Summary</span>
        </v-card-title>
        <v-card-text>
          <!-- Loader -->
          <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
          ></v-progress-linear>

          <!-- Table -->
          <v-simple-table v-if="!loading">
            <thead>
              <tr>
                <th>Lead Description</th>
                <th>Count</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in leadDescriptionSummary" :key="index">
                <td>
                  <v-text-field v-model="item.leadDescription" outlined />
                </td>
                <td>
                  <v-text-field v-model="item.count" outlined />
                </td>
                <td>
                  <v-btn icon @click="deleteRow(index)">
                    <v-icon color="red">mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td colspan="3" class="text-center">
                  <v-btn color="green" @click="addRow">Add Row</v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeDialogOpenSalesOne">Next</v-btn>
          <v-btn color="secondary" @click="dialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="salesConversionDialog" max-width="1800px">
      <v-card>
        <v-card-title>
          <span class="text-h6">Sales Conversion</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-data-table
                  :items="salesConversion"
                  :headers="salesConversionHeaders"
                  item-value="index"
                  dense
                  class="elevation-1"
                  hide-default-footer
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title
                        >Editable Sales Conversion</v-toolbar-title
                      >
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="addSalesConversionRow"
                        >Add Row</v-btn
                      >
                    </v-toolbar>
                  </template>
                  <template v-slot:body="{ items }">
                    <tr v-for="(item, index) in items" :key="index">
                      <td>
                        <simple-consultant-picker
                          @consultant-selected="item.agentName"
                        />
                      </td>
                      <td>
                        <v-text-field
                          v-model="item.converted"
                          type="number"
                          dense
                          outlined
                        />
                      </td>
                      <td>
                        <v-text-field
                          v-model="item.premiumVolume"
                          prefix="R"
                          type="number"
                          dense
                          outlined
                        />
                      </td>
                      <td>
                        <v-text-field
                          :value="calculateAveragePremium(item)"
                          dense
                          outlined
                          readonly
                        />
                      </td>
                      <td>
                        <v-btn
                          color="red"
                          @click="removeSalesConversionRow(item)"
                          >Remove</v-btn
                        >
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeSalesDialogOpenNextOne"
            >Next</v-btn
          >
          <v-btn color="primary" @click="salesConversionDialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Sales box -->
    <v-dialog v-model="commissionDialog" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span>Sales Summary</span>
        </v-card-title>
        <v-card-text>
          <v-simple-table v-if="!loading">
            <thead>
              <tr>
                <th>Product</th>
                <th>Count</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in salesSummary" :key="index">
                <td>
                  <sales-picker
                    :selected-source="source"
                    @product="(value) => (item.product = value)"
                  />
                </td>
                <td>
                  <v-text-field v-model="item.count" label="Qty" outlined />
                </td>
                <td>
                  <v-icon color="red" @click="deleteSalesRow(index)"
                    >mdi-delete</v-icon
                  >
                </td>
              </tr>
              <tr>
                <td colspan="3" class="text-center">
                  <v-btn color="green" @click="addSalesRow">Add Row</v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="saveEdits">Run report</v-btn>
          <v-btn color="secondary" @click="commissionDialog = false"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  CREATE_AND_SEND_REPORT,
  ACCEPT_LEAD_DESCRIPTION_SUMMARY,
} from "@/queries/users";
import { gql } from "apollo-boost";
import SalesPicker from "./SalesPicker.vue";
import SimpleConsultantPicker from "../Pickers/SimpleConsultantPicker.vue";

export default {
  components: { SalesPicker, SimpleConsultantPicker },
  name: "NewMonthlyReport",
  data() {
    return {
      start: "", // Start date
      end: "", // End date
      source: "", // Source (with v-select options)
      reportMessage: null,
      type: "Custom",
      salesConversionDialog: false,
      salesConversion: [],
      menu: false,
      salesConversionHeaders: [
        { text: "Agent Name", value: "agentName" },
        { text: "Converted", value: "converted" },
        { text: "Premium Volume", value: "premiumVolume" },
        { text: "Average Premium", value: "averagePremium" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      commissionDialog: false,
      editedLeadDesc: [],
      showModal: false,
      startMenu: false,
      endMenu: false,
      dialog: false,
      loading: false,
      leadDescriptionSummary: [],
      month: null,
      salesSummary: [],
      typeItems: [
        { text: "Monthly", value: "Monthly" },
        { text: "Custom", value: "Custom" },
      ],

      months: [
        { text: "January", value: "Monthly" },
        { text: "February", value: "Custom" },
      ],
    };
  },
  methods: {
    async fetchLeadDescriptionSummary() {
      this.loading = true;
      try {
        const response = await this.$apollo.query({
          query: gql`
            query LeadDescriptionSummary(
              $startDate: String!
              $endDate: String!
              $source: String!
            ) {
              findLeadDescriptionSummary(
                startDate: $startDate
                endDate: $endDate
                source: $source
              ) {
                leadDescription
                count
              }
            }
          `,
          variables: {
            startDate: this.start,
            endDate: this.end,
            source: this.source,
          },
        });
        this.leadDescriptionSummary =
          response.data.findLeadDescriptionSummary || [];
      } catch (error) {
        console.error("Error fetching lead description summary:", error);
      } finally {
        this.loading = false;
      }
    },
    onTypeChange() {
      console.log(`Type changed to: ${this.type}`);
      if (this.type !== "Monthly") {
        this.month = null;
        console.log("Reset month as type is not Monthly.");
      } else {
        console.log("Monthly type selected. Awaiting month selection.");
      }
    },
    async updateMonthlyDates() {
      console.log(`Month selected: ${this.month}`);
      if (this.month) {
        // Example: this.month = "2025-01"
        // Splitting the string "YYYY-MM" into numeric year and month
        const [year, month] = this.month.split("-").map(Number);

        // First day (local)
        const firstDay = new Date(year, month - 1, 1);

        // Last day (local): "day 0" of the next month
        // e.g. for January (month = 1), new Date(year, 1, 0) gives Jan 31.
        const lastDay = new Date(year, month, 0);

        // Because toISOString() uses UTC and can shift the date if your local timezone is "behind" UTC,
        // we shift to "UTC midnight" by adjusting for getTimezoneOffset() before formatting.
        // This ensures the final YYYY-MM-DD still matches local calendar dates.

        const firstDayUtc = new Date(
          firstDay.getTime() - firstDay.getTimezoneOffset() * 60000
        );
        const lastDayUtc = new Date(
          lastDay.getTime() - lastDay.getTimezoneOffset() * 60000
        );

        // Extract the YYYY-MM-DD parts from the UTC-based date
        this.start = firstDayUtc.toISOString().substr(0, 10);
        this.end = lastDayUtc.toISOString().substr(0, 10);

        console.log("Start:", this.start); // e.g. "2025-01-01"
        console.log("End:", this.end); // e.g. "2025-01-31"

        await this.applyDateRange();
      } else {
        console.warn("No month selected, unable to update dates.");
      }
    },

    async applyDateRange() {
      this.menu = false;
      // await this.fetchLeadDescriptionSummary();
    },
    async runReportWithDialog() {
      this.dialog = true;
      await this.fetchLeadDescriptionSummary();
    },
    async closeMainDialogOpenOther() {
      this.showModal = false;
      await this.fetchLeadDescriptionSummary();
      this.dialog = true;
    },

    async runReport() {
      try {
        // Run the mutation to create and send the report
        const response = await this.$apollo.mutate({
          mutation: CREATE_AND_SEND_REPORT,
          variables: {
            start: this.start,
            end: this.end,
            source: this.source,
            leadSummary: this.editedLeadDesc,
            salesConversion: this.salesConversion,
            salesSummary: this.salesSummary,
            type: this.type,
          },
        });
        console.log(this.type);

        const { success, message } = response.data.createAndSendReport;
        this.showModal = false;

        // Display success or error message using Swal
        if (success) {
          this.$swal(
            "Success",
            message || "Report generated and emailed successfully.",
            "success"
          );
        } else {
          this.$swal("Error", "Report generation failed.", "error");
        }
      } catch (error) {
        console.error("Error creating and sending report:", error);
        this.$swal(
          "Error",
          "An error occurred while creating the report.",
          "error"
        );
      }
    },

    async closeDialogOpenSalesOne() {
      this.dialog = false;
      this.salesConversionDialog = true;
      this.addSalesConversionRow();
      // this.addSalesRow();
    },

    async closeSalesDialogOpenNextOne() {
      this.salesConversionDialog = false;
      this.commissionDialog = true;
      this.addSalesRow();
    },
    addRow() {
      this.leadDescriptionSummary.push({ leadDescription: "", count: 0 });
    },
    deleteRow(index) {
      this.leadDescriptionSummary.splice(index, 1);
    },

    addSalesRow() {
      this.salesSummary.push({ product: "", count: 0 });
    },
    deleteSalesRow(index) {
      this.salesSummary.splice(index, 1);
    },
    async saveEdits() {
      this.editedLeadDesc = [...this.leadDescriptionSummary];
      this.commissionDialog = false;
      await this.runReport();
    },
    addSalesConversionRow() {
      this.salesConversion.push({
        agentName: "Rene Naidoo (Medsafu)- from Medsafu Sinoville",
        converted: null,
        premiumVolume: null,
        averagePremium: null,
      });
    },
    removeSalesConversionRow(row) {
      const index = this.salesConversion.indexOf(row);
      if (index > -1) {
        this.salesConversion.splice(index, 1);
      }
    },
    calculateAveragePremium(row) {
      if (row.converted && row.premiumVolume) {
        return (row.premiumVolume / row.converted).toFixed(2);
      }
      return 0;
    },
  },
};
</script>

<style scoped>
/* Add any component-specific styles here */
</style>
