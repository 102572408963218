<template>
  <div>
    <v-select
      :items="filteredItems"
      v-model="selectedItem"
      label="Select Item"
      outlined
      @change="emitProduct"
    />
  </div>
</template>

<script>
export default {
  name: "SalesPicker",
  props: {
    selectedSource: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      localSelectedSource: this.selectedSource, // Use the prop as the initial value
      selectedItem: null,
      items: [
        { text: "BonCap", value: "BonCap", source: "Bonitas" },
        { text: "BonEssential", value: "BonEssential", source: "Bonitas" },
        {
          text: "BonEssential Select",
          value: "BonEssential Select",
          source: "Bonitas",
        },
        { text: "Bon Fit", value: "Bon Fit", source: "Bonitas" },
        { text: "BonFit Select", value: "BonFit Select", source: "Bonitas" },
        { text: "BonSave", value: "BonSave", source: "Bonitas" },
        { text: "BonStart", value: "BonStart", source: "Bonitas" },
        { text: "Bon Start Plus", value: "Bon Start Plus", source: "Bonitas" },
        { text: "Primary Select", value: "Primary Select", source: "Bonitas" },
        { text: "Primary", value: "Primary", source: "Bonitas" },
        { text: "BonComplete", value: "BonComplete", source: "Bonitas" },
        {
          text: "BonComprehensive",
          value: "BonComprehensive",
          source: "Bonitas",
        },
        { text: "BonClassic", value: "BonClassic", source: "Bonitas" },
        {
          text: "Hospital Standard",
          value: "Hospital Standard",
          source: "Bonitas",
        },
        {
          text: "Standard Select",
          value: "Standard Select",
          source: "Bonitas",
        },
        {
          text: "Standard",
          value: "Standard",
          source: "Bonitas",
        },
        { text: "MediPhila", value: "MediPhila", source: "Medshield" },
        { text: "MediCurve", value: "MediCurve", source: "Medshield" },
        { text: "Medicore", value: "Medicore", source: "Medshield" },
        { text: "MediSaver", value: "MediSaver", source: "Medshield" },
        {
          text: "MediPlus",
          value: "MediPlus",
          source: "Medshield",
        },
        {
          text: "MediBonus",
          value: "MediBonus",
          source: "Medshield",
        },
        {
          text: "MediPlus Prime",
          value: "MediPlus Prime",
          source: "Medshield",
        },
        {
          text: "MediPlus Compact",
          value: "MediPlus Compact",
          source: "Medshield",
        },
        { text: "Mediswift", value: "Mediswift", source: "Medshield" },
        {
          text: "MediValue Compact",
          value: "MediValue Compact",
          source: "Medshield",
        },
        {
          text: "MediValue Prime",
          value: "MediValue Prime",
          source: "Medshield",
        },
        { text: "Premium Plus", value: "Premium Plus", source: "Medshield" },
      ],
    };
  },
  computed: {
    sources() {
      return [...new Set(this.items.map((item) => item.source))];
    },
    filteredItems() {
      return this.items.filter(
        (item) => item.source === this.localSelectedSource
      );
    },
  },
  methods: {
    emitProduct() {
      // Emit the selected value as "product"
      this.$emit("product", this.selectedItem);
    },
  },
};
</script>
